var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticStyle: {
      background: "#ececec",
      padding: "15px",
      "margin-bottom": "8px"
    }
  }, [_c("a-card", {
    staticStyle: {
      width: "600px"
    },
    attrs: {
      title: "",
      bordered: false
    }
  }, [_c("div", {
    staticClass: "videoInfo"
  }, [_c("img", {
    attrs: {
      src: _vm.itemInfo.coverurl,
      alt: ""
    }
  }), _c("div", {
    staticClass: "desc"
  }, [_c("p", [_vm._v("视频标题: " + _vm._s(_vm.itemInfo.title))]), _c("p", [_vm._v("视频ID: " + _vm._s(_vm.itemInfo.id))])]), _c("div", {
    staticClass: "action"
  }, [_c("span", {
    on: {
      click: _vm.toShow
    }
  }, [_vm._v("查看链接")]), _c("span", {
    on: {
      click: _vm.toDel
    }
  }, [_vm._v("删除")])])])])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };