import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form-model", {
    ref: "dcCategoryForm",
    attrs: {
      labelCol: {
        style: "width: 100px"
      },
      model: _vm.form
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "标题",
      prop: "title",
      rules: {
        required: true,
        message: "请输入标题"
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      autoComplete: "off",
      placeholder: "请输入标题",
      disabled: _vm.isDisabled,
      "max-length": 200
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "简介",
      prop: "profile",
      rules: {
        required: true,
        message: "请输入简介"
      }
    }
  }, [_c("a-input", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      type: "textarea",
      rows: 5,
      autoComplete: "off",
      placeholder: "请输入简介",
      disabled: _vm.isDisabled,
      "max-length": 2000
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    },
    model: {
      value: _vm.form.profile,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "profile", $$v);
      },
      expression: "form.profile"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "关联用户",
      prop: "userid",
      rules: {
        required: true,
        message: "请选择关联用户"
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      "show-search": "",
      value: _vm.userValue,
      placeholder: "输入用户名搜索",
      "default-value": _vm.userValue,
      "default-active-first-option": false,
      "show-arrow": false,
      "filter-option": false,
      "not-found-content": null
    },
    on: {
      search: _vm.userHandleSearch,
      change: _vm.userHandleChange
    }
  }, _vm._l(_vm.usersList, function (d) {
    return _c("a-select-option", {
      key: d.id
    }, [_vm._v(" " + _vm._s(d.username) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "关联视频选择",
      prop: "videoids",
      rules: {
        required: true,
        message: "请选择关联视频"
      }
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "350px"
    },
    attrs: {
      mode: "multiple",
      "label-in-value": "",
      value: _vm.videoValue,
      placeholder: "输入视频ID搜索",
      "filter-option": false,
      "not-found-content": _vm.fetching ? undefined : null
    },
    on: {
      search: _vm.fetchVideo,
      change: _vm.videoHandleChange
    }
  }, [_vm.fetching ? _c("a-spin", {
    attrs: {
      slot: "notFoundContent",
      size: "small"
    },
    slot: "notFoundContent"
  }) : _vm._e(), _vm._l(_vm.videoList, function (d) {
    return _c("a-select-option", {
      key: d.id
    }, [_vm._v(" " + _vm._s(d.title) + " ")]);
  })], 2)], 1), _vm.form.videoids ? _c("a-form-model-item", {
    attrs: {
      label: "已选择关联视频"
    }
  }, _vm._l(_vm.form.videoids, function (videoId) {
    return _c("videoCard", {
      key: videoId,
      attrs: {
        editId: videoId
      },
      on: {
        delVideo: _vm.delVideo
      }
    });
  }), 1) : _vm._e()], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_r",
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.disabledLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "提交"))])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };