var indexSearchKeys = [{
  key: "createdate",
  label: "创建时间",
  placeholder: ['开始时间', '截止时间'],
  required: false,
  rules: [],
  dates: true
}, {
  key: "username",
  label: "创建用户",
  placeholder: "请输入创建用户",
  required: false,
  rules: [],
  input: true
}, {
  key: "title",
  label: "合集标题",
  placeholder: "请输入合集标题",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { indexSearchKeys };