import "core-js/modules/es.number.constructor.js";
import { CourseManageApi } from "@/api";
export default {
  name: "dcVideoCard",
  components: {},
  props: {
    editId: [String, Number]
  },
  data: function data() {
    return {
      isEdit: "",
      disabledLoading: false,
      itemInfo: {}
    };
  },
  computed: {},
  mounted: function mounted() {
    this.getVideoInfo();
  },
  methods: {
    getVideoInfo: function getVideoInfo() {
      var _this = this;
      CourseManageApi.searchVideo({
        id: this.editId
      }).then(function (res) {
        _this.itemInfo = res;
      });
    },
    toShow: function toShow() {
      window.open(this.itemInfo.contenturl, "_blank");
    },
    toDel: function toDel() {
      this.$emit("delVideo", this.itemInfo.id);
    }
  }
};