var indexColumns = [{
  title: '合集ID',
  dataIndex: 'id',
  key: 'id',
  width: '20%'
}, {
  title: '合集标题',
  dataIndex: 'title',
  key: 'title',
  width: '10%'
}, {
  title: '合集简介',
  dataIndex: 'profile',
  key: 'profile',
  width: '20%'
}, {
  title: '创建用户',
  dataIndex: 'username',
  key: 'username',
  width: '10%'
}, {
  title: '创建时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '10%'
}, {
  title: 'Preview',
  dataIndex: 'coverurl',
  key: 'coverurl',
  width: '10%',
  scopedSlots: {
    customRender: 'coverurl'
  }
}, {
  title: '操作',
  dataIndex: 'action',
  key: 'action',
  width: '10%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { indexColumns };